<template lang="pug">
.fgp(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  v-container.d-flex.align-center.justify-center.w-100
    v-row.justify-center.mt-5(style="flex-shrink: 1; flex-grow: 1")
      v-col.d-flex.justify-center.align-center(cols="12")
        h1.text-center.login-title 忘記密碼
      v-col.d-flex.justify-center.align-center(cols="12")
        p.text_primary.text-center 密碼將寄至您的會員Email信箱
      v-col(cols="10", md="5")
        form.form-login(method="post", style="margin-top: 40px")
          .input_container
            input#id_username(
              required,
              type="email",
              name="username",
              placeholder="輸入會員帳號",
              autofocus="",
              v-model="email"
            )
            svg-icon(iconClass="user_hollow", className="user_hollow")
          .btn.btn.login.w-100(
            style="margin-top: 36px",
            @click="sendVerifyEmail"
          ) 寄送忘記密碼函

          .btn.btn.sign_up.mt-6(@click="toLogin") 回登入
      v-col.d-flex.justify-center.align-center(cols="12")
        v-footer(align="center")
          .footer
            h5 Copyright © 2022 競晰股份有限公司
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import { validEmail } from "@/utils/validate";
import { resetPassword } from "@/api/user";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";

export default {
  name: "ForgetPassword",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      email: "",

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "忘記密碼 | Game Changer",
        description: "忘記密碼",
        keywords: "Game changer,籃球,會員,忘記密碼",
        url: "https://www.gamechanger.tw/forgetpassword",
        image: "",
        smoType: "website",
      },
    };
  },
  methods: {
    sendVerifyEmail() {
      if (!validEmail(this.email)) {
        this.showErrorDialog("錯誤", "信箱格式錯誤");
        return;
      }
      this.showLoadingDialog("資料確認中...");
      resetPassword({ email: this.email })
        .then(() => {
          this.closeLoadingDialog();
          this.showSuccessDialog("成功", "修改密碼確認信已送出，請至信箱確認");
          this.email = "";
        })
        .catch(() => {
          this.closeLoadingDialog();
          this.showErrorDialog("失敗", "請確認信箱是否正確");
        });
    },
    toLogin() {
      this.$router.push({ name: "Login" });
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/user/forget_password";
</style>
